import './PDFCompareViewer.scss'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'

import { Document, Page, pdfjs } from 'react-pdf'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { alignPages, useCompareGraphics } from './pdf-comparison'

import { FileSearchOutlined } from '@ant-design/icons'
import { OverlayLoader } from '@app/common/layout/components/OverlayLoader'

pdfjs.GlobalWorkerOptions.workerSrc = './pdf.worker.js'

// Compare text elements on aligned pages
const compareTextElements = (elements1, elements2, pageIndex) => {
  const diffs = []
  const tolerance = 15 // Adjust this value as needed for position tolerance
  let diffIndex = 0

  const areElementsEqual = (el1, el2) => {
    const [x1, y1] = el1.transform || [0, 0]
    const [x2, y2] = el2.transform || [0, 0]

    const positionEqual = Math.abs(x1 - x2) <= tolerance && Math.abs(y1 - y2) <= tolerance
    return positionEqual && el1.str === el2.str
  }

  elements1.forEach((el1) => {
    const matchingElement = elements2.find(el2 => areElementsEqual(el1, el2))
    if (!matchingElement) {
      diffs.push({ id: `diff-${pageIndex}-${diffIndex++}`, type: 'removed', oldElement: el1 })
    }
  })

  elements2.forEach((el2) => {
    const matchingElement = elements1.find(el1 => areElementsEqual(el1, el2))
    if (!matchingElement) {
      diffs.push({ id: `diff-${pageIndex}-${diffIndex++}`, type: 'added', newElement: el2 })
    }
  })

  return diffs
}

const extractElements = async (pdf) => {
  const elements = []
  for (let i = 1; i <= pdf.numPages; i++) {
    const page = await pdf.getPage(i)
    const textContent = await page.getTextContent()
    const pageElements = textContent.items.map(item => ({
      str: item.str,
      transform: item.transform ? item.transform.slice(4, 6) : [0, 0],
      fontName: item.fontName,
      width: item.width,
      height: item.height,
    }))
    elements.push(pageElements)
  }
  return elements
}

// Extract elements from PDFs
const onDocumentLoad = async (pdf, setElements) => {
  const elements = await extractElements(pdf)
  setElements(elements)
}

const ProgressOverlay = ({ progress }) => (
  <div className="progress-overlay">
    <div className="progress-container">
      <p>Comparing PDFs...</p>
      <progress value={progress} max="100">{progress}%</progress>
    </div>
  </div>
)

// Render highlights for text differences
const Highlights = ({ diffs, pageIndex, pdfType }) => {
  return diffs.map((diff, index) => {
    const { oldElement, newElement, type, id } = diff
    const element = pdfType === 'left' ? oldElement : newElement
    if (!element) { return null }

    const [x, y] = element.transform || [0, 0]
    const width = element.width || 0
    const height = element.height || 0
    const style = {
      position: 'absolute',
      left: `${x}px`,
      bottom: `${y}px`,
      width: `${width}px`,
      height: `${height}px`,
      backgroundColor: pdfType === 'left' ? (type === 'removed' ? 'red' : 'transparent') : (type === 'added' ? 'green' : 'transparent'),
      opacity: 0.5,
      zIndex: 1,
    }
    return <div id={id} key={id} style={style} />
  })
}

// Render highlights for graphics differences
const GraphicsHighlights = ({ diffs, pageIndex, pdfType }) => {
  const diffData = diffs.filter(
    (diff) =>
      diff.page === pageIndex + 1 &&
      diff.type === (pdfType === 'left' ? 'added' : 'removed') // Swapped 'added' and 'removed'
  )
  if (!diffData.length) { return null }

  return diffData.map((box, idx) => {
    const { id } = box
    const style = {
      position: 'absolute',
      left: `${box.x}px`,
      top: `${box.y}px`,
      width: `${box.width}px`,
      height: `${box.height}px`,
      backgroundColor: box.type === 'removed' ? 'green' : 'red',
      opacity: 0.5,
      zIndex: 2,
    }
    return <div id={id} key={id} style={style}></div>
  })
}

// Render placeholders for added or removed pages
const Placeholder = React.memo(({ type }) => (
  <div className="pdf-placeholder" style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '1000px',
    color: 'white',
    marginBottom: '10px',
    fontWeight: 'bold',
    backgroundColor: type === 'added' ? 'green' : '#a83232',
    fontSize: '17px',
  }}>
    <p>{type === 'added' ? 'Page Added →' : '← Page Removed'}</p>
  </div>
))

// Render change log
const ChangeLog = React.memo(({ diffs, pageIndex, checkboxDiffs, scrollToHighlight }) => {
  const pageDiffs = diffs[pageIndex] || []
  const checkboxChanges = checkboxDiffs.filter(diff => diff.page === pageIndex + 1) // Extract checkbox changes for this page

  // Group diffs by position (x, y)
  const groupedDiffs = pageDiffs.reduce((acc, diff) => {
    const { oldElement, newElement } = diff
    const element = oldElement || newElement
    const [x, y] = element.transform || [0, 0]

    const positionKey = `${x}-${y}`
    if (!acc[positionKey]) {
      acc[positionKey] = []
    }
    acc[positionKey].push(diff)
    return acc
  }, {})

  return (
    <div className="page-change-log">
      <div className="page-header">
        PAGE {pageIndex + 1} - CHANGES DETECTED
      </div>

      {/* Data Points Section */}
      <div className="data-points">
        {/* Grouped Text Changes */}
        {Object.keys(groupedDiffs).length > 0 ? (
          Object.entries(groupedDiffs).map(([position, groupedChanges], index) => {
            let oldValue = ''
            let newValue = ''
            let diffId = ''
            let pdfType = ''

            // Use the first diff in the group for the ID and pdfType
            const firstDiff = groupedChanges[0]
            diffId = firstDiff.id
            pdfType = firstDiff.type === 'added' ? 'right' : 'left'

            // Loop through all changes and collect old and new values
            groupedChanges.forEach((diff) => {
              if (diff.oldElement) {
                oldValue += `${diff.oldElement.str} `
              }
              if (diff.newElement) {
                newValue += `${diff.newElement.str} `
              }
            })

            // Trim any extra spaces
            oldValue = oldValue.trim()
            newValue = newValue.trim()

            // Only render the section if either oldValue or newValue exists
            if (!oldValue && !newValue) {
              return null // Skip rendering if both old and new are empty
            }

            return (
              <div key={`change-log-${pageIndex}-${index}`} className="data-point">
                <div className="change-label">
                  <span>Data Point {index + 1}</span>
                  <button className="go-to-change-button" onClick={() => scrollToHighlight(diffId, pdfType, pageIndex)}>
                    <FileSearchOutlined />
                  </button>
                </div>

                {/* Display Old and New only if they exist */}
                {oldValue && (
                  <div className="change-detail">
                    <span className="data-label">Old: </span>
                    <span className="data-value removed">{oldValue}</span>
                  </div>
                )}
                {newValue && (
                  <div className="change-detail">
                    <span className="data-label">New: </span>
                    <span className="data-value added">{newValue}</span>
                  </div>
                )}
              </div>
            )
          })
        ) : (
          <p className="no-change-text">No text changes detected.</p>
        )}

        {/* Checkbox Changes */}
        {checkboxChanges.length > 0 ? (
          checkboxChanges.map((checkboxChange, idx) => {
            const diffId = checkboxChange.id
            const pdfType = checkboxChange.type === 'added' ? 'left' : 'right' // Swapped to match earlier logic

            return (
              <div key={`checkbox-change-${pageIndex}-${idx}`} className="data-point">
                <div className="change-label">
                  <span>Checkbox Change {idx + 1}</span>
                  <button className="go-to-change-button" onClick={() => scrollToHighlight(diffId, pdfType)}>
                    <FileSearchOutlined />
                  </button>
                </div>
                <div className="change-detail">
                  <span>{checkboxChange.type === 'added' ? 'Checkbox checked' : 'Checkbox unchecked'}</span>
                </div>
              </div>
            )
          })
        ) : (
          <p className="no-change-text">No checkbox changes detected.</p>
        )}
      </div>
    </div>
  )
})

export const PDFCompareViewer = React.memo(({ file1, file2 }) => {
  const [numPages1, setNumPages1] = useState(null)
  const [numPages2, setNumPages2] = useState(null)
  const [pdfDoc1, setPdfDoc1] = useState(null)
  const [pdfDoc2, setPdfDoc2] = useState(null)
  const [pdfElements1, setPdfElements1] = useState([])
  const [pdfElements2, setPdfElements2] = useState([])
  const [alignedPages, setAlignedPages] = useState([])
  const [textDiffs, setTextDiffs] = useState([])
  const [checkboxDiffs, setCheckboxDiffs] = useState([])
  const { compareGraphics, progress, isProcessing } = useCompareGraphics()

  const pageRefsLeft = useRef([])
  const pageRefsRight = useRef([])
  const containerRef1 = useRef(null)
  const containerRef2 = useRef(null)
  const isSyncingRef = useRef(false)

  const scale = 1.0

  // Synchronize scrolling between PDFs
  const handlePDFScroll = (e) => {
    if (isSyncingRef.current) { return }
    isSyncingRef.current = true

    const container1 = containerRef1.current
    const container2 = containerRef2.current
    const { scrollTop } = e.target

    if (container1 && e.target === container1) {
      container2.scrollTop = scrollTop
    } else if (container2 && e.target === container2) {
      container1.scrollTop = scrollTop
    }

    isSyncingRef.current = false
  }

  useEffect(() => {
    const container1 = containerRef1.current
    const container2 = containerRef2.current

    if (container1) { container1.addEventListener('scroll', handlePDFScroll) }
    if (container2) { container2.addEventListener('scroll', handlePDFScroll) }

    return () => {
      if (container1) { container1.removeEventListener('scroll', handlePDFScroll) }
      if (container2) { container2.removeEventListener('scroll', handlePDFScroll) }
    }
  }, [numPages1, numPages2])

  const onDocumentLoadSuccess1 = (pdf) => {
    setNumPages1(pdf.numPages)
    setPdfDoc1(pdf) // Set the first PDF document
    onDocumentLoad(pdf, setPdfElements1)
  }

  const onDocumentLoadSuccess2 = (pdf) => {
    setNumPages2(pdf.numPages)
    setPdfDoc2(pdf) // Set the second PDF document
    onDocumentLoad(pdf, setPdfElements2)
  }

  // Process PDFs after elements are extracted
  useEffect(() => {
    const processDiffs = async () => {
      if (pdfElements1.length > 0 && pdfElements2.length > 0) {
        // Align pages
        const aligned = alignPages(pdfElements1, pdfElements2)
        setAlignedPages(aligned)

        // Compare text on aligned pages
        const diffsPerPage = aligned.map((alignedPage, pageIndex) => {
          if (alignedPage.type === 'matched') {
            return compareTextElements(alignedPage.elements1, alignedPage.elements2, pageIndex)
          }
          return []
        })
        setTextDiffs(diffsPerPage)

        // Compare graphics (e.g., checkboxes)
        const graphicsDiffs = await compareGraphics(pdfDoc1, pdfDoc2, aligned)
        setCheckboxDiffs(graphicsDiffs)
      }
    }

    processDiffs()
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [pdfElements1, pdfElements2])

  // Scroll to highlight function
  const scrollToHighlight = useCallback((id, pdfType, pageIndex) => {
    const container = pdfType === 'left' ? containerRef1.current : containerRef2.current
    const pageRef = pdfType === 'left' ? pageRefsLeft.current[pageIndex] : pageRefsRight.current[pageIndex]
    let isScrolling = false

    const attemptScroll = (attemptsLeft) => {
      if (isScrolling) { return }
      isScrolling = true

      const element = document.getElementById(id)

      if (element && container && pageRef) {
        // Get the offsetTop of the element relative to the page container
        const elementOffsetTop = element.offsetTop

        // Get the offsetTop of the page container relative to the container
        const pageOffsetTop = pageRef.offsetTop

        // Total offset is the sum of the page's offset and the element's offset within the page
        const totalOffset = pageOffsetTop + elementOffsetTop

        // Adjust the scroll position to bring the element into view
        container.scrollTo({
          top: totalOffset - container.clientHeight / 2 + element.offsetHeight / 2,
        })

        // Check if the element is approximately centered
        const containerRect = container.getBoundingClientRect()
        const elementRect = element.getBoundingClientRect()
        const elementCenter = elementRect.top + elementRect.height / 2
        const containerCenter = containerRect.top + containerRect.height / 2
        const isCentered = Math.abs(elementCenter - containerCenter) < 5 // Threshold in pixels

        if (!isCentered && attemptsLeft > 0) {
          // Try again after a delay
          setTimeout(() => attemptScroll(attemptsLeft - 1), 100)
        } else {
          isScrolling = false
        }
      } else if (attemptsLeft > 0) {
        // If the element is not found, wait and try again
        setTimeout(() => attemptScroll(attemptsLeft - 1), 100)
      } else {
        isScrolling = false
      }
    }

    // Start the attempt with a maximum number of retries
    attemptScroll(1000)
  }, [])

  return (
    <OverlayLoader isLoading={progress <= 0}>
      <div className="pdf-compare-viewer__container" style={{ display: 'flex', height: '100vh', position: 'relative', backgroundColor: 'rgba(25,25,25,0.05)' }}>
        {(isProcessing && progress > 0) && <ProgressOverlay progress={progress} />} {/* Render overlay while processing */}
        <div style={{ width: '20%', overflowY: 'scroll', height: '100%', padding: '10px', borderRight: '1px solid #ccc' }}>
          <div className="changes-log">
            {alignedPages.map((aligned, index) => (
              <div key={`page-log-${index}`}>
                <ChangeLog
                  diffs={textDiffs}
                  pageIndex={index}
                  checkboxDiffs={checkboxDiffs}
                  scrollToHighlight={scrollToHighlight}
                />
              </div>
            ))}
          </div>
        </div>
        <div style={{ position: 'relative', width: '80%', overflowY: 'scroll', height: '100%' }}>
          <div style={{ display: 'flex', width: '100%', height: '100%' }}>
            <div style={{ width: '50%', overflowY: 'scroll', paddingRight: '2%' }} ref={containerRef1}>
              <Document
                file={file1}
                onLoadSuccess={onDocumentLoadSuccess1}
                onLoadError={(error) => console.error('Error loading PDF 1:', error)}
              >
                {alignedPages.map((aligned, index) => (
                  <div ref={(el) => (pageRefsLeft.current[index] = el)}
                    key={`page1_${index + 1}`} style={{ position: 'relative' }}>
                    {aligned.type === 'added' ? (
                      <div className='pdf-page'>
                        <Placeholder type="added" />
                      </div>
                    ) : (
                      <Page
                        pageNumber={aligned.pageNum1}
                        scale={scale}
                        renderTextLayer
                        className='pdf-page'
                      />
                    )}
                    {aligned.type !== 'added' && <Highlights diffs={textDiffs[index] || []} pageIndex={index} pdfType="left" />}
                    {aligned.type !== 'added' && <GraphicsHighlights diffs={checkboxDiffs} pageIndex={index} pdfType="left" />}
                  </div>
                ))}
              </Document>
            </div>
            <div style={{ width: '50%', overflowY: 'scroll' }} ref={containerRef2}>
              <Document
                file={file2}
                onLoadSuccess={onDocumentLoadSuccess2}
                onLoadError={(error) => console.error('Error loading PDF 2:', error)}
              >
                {alignedPages.map((aligned, index) => (
                  <div ref={(el) => (pageRefsRight.current[index] = el)} key={`page2_${index + 1}`} style={{ position: 'relative' }}>
                    {aligned.type === 'removed' ? (
                      <div className='pdf-page'>
                        <Placeholder type="removed" />
                      </div>
                    ) : (
                      <Page
                        pageNumber={aligned.pageNum2}
                        scale={scale}
                        renderTextLayer
                        className='pdf-page'
                      />
                    )}
                    {aligned.type !== 'removed' && <Highlights diffs={textDiffs[index] || []} pageIndex={index} pdfType="right" />}
                    {aligned.type !== 'removed' && <GraphicsHighlights diffs={checkboxDiffs} pageIndex={index} pdfType="right" />}
                  </div>
                ))}
              </Document>
            </div>
          </div>
        </div>
      </div>
    </OverlayLoader>
  )
})
