import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import {
  checkPermission,
  getUTCTime,
  utcToLocal,
  buildAvatars,
  checkCanViewAppraiserNameOnOrder,
  getRUCCTypeFromCode,
} from '../../common/utils/helpers'
import { FORM_KEYS_TO_READABLE } from '../../common/constants/forms'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { BarsOutlined, FormOutlined, ProfileOutlined } from '@ant-design/icons'
import { Tag, Popover } from 'antd'
import { Button } from 'reactstrap'
import moment from 'moment'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import ModalTrigger from '../../common/modals/ModalTrigger'
import { ChatWrapper } from '../../common/widgets/ChatWrapper'
import EditAssignedModal from './EditAssignedModal'
import EditAMCManagementFeeModal from './EditAMCManagementFeeModal'
import UnansweredMessageInfo from './UnansweredMessageInfo'
import NewMessageContainer from './NewMessageContainer'
import CustomTimelineItem from './CustomTimelineItem'
import Notes from './Notes'
import { history } from '../../init'
import { paymentOptionsToReadable } from '../../common/constants/borrowerPaymentOptions'
import { SmallLoader } from '../../common/layout/components/Loader'
import CommentTemplateDropdown from './CommentTemplateDropdown'
import { isPdrOrder } from '../../../helpers/pdr_orders'
import Rephrase from '../../common/utils/Rephrase'
import { formatCurrency } from '@common/formatters/currency'

const CheckableTag = Tag.CheckableTag
const MESSAGE_FILTER = 'Messages'
const STATUS_FILTER = 'Statuses'
const REMINDER_FILTER = 'Reminders'

function getOrderStatus(orderData, is_amc_lender) {
  const { revision_status, job_type } = orderData
  // Copied this logic from renderStatusBadge in helpers.js.
  // TODO: Add redux selectors to provide a consistent way
  // of selecting data from the store.
  if (revision_status && (job_type !== 'amc' || is_amc_lender)) {
    return orderData.revision_status
  }

  return orderData.status === 'Pending Review' ? 'Submitted' : orderData.status
}

function createTimelineEvent(
  description,
  subDescription,
  iconType,
  iconColor,
  tagText,
  additionalInfo,
  viewReasonText,
  initiator,
  initiator_type,
  tooltipPermission,
  hideTooltip,
  amcLender,
  amcOrder,
  orderData,
  eventIndex
) {
  const key = `${description}${subDescription}${eventIndex}`

  return (
    <CustomTimelineItem
      key={key}
      dot={<LegacyIcon type={iconType} />}
      color={iconColor}
      description={description}
      subDescription={subDescription}
      tagText={tagText}
      additionalInfo={additionalInfo}
      viewReasonText={viewReasonText}
      initiator={initiator}
      initiator_type={initiator_type}
      tooltipPermission={tooltipPermission}
      hideTooltip={hideTooltip}
      amcLender={amcLender}
      amcOrder={amcOrder}
      orderData={orderData}
    />
  )
  /*
    if(dueDate && dueDate !== "None") {
      let color
      let tagText
      if(isPastDue && !order.lender_canceled) {
        color ='red'
        tagText = 'Behind Schedule'
      } else if(order.lender_canceled) {
        color = 'grey'
      } else {
        color = 'grey'
        tagText = 'On Schedule'
      }
            return <CustomTimelineItem
        key={key}
        dot={<Icon type="clock-circle-o" />}
        color={color}
        description={eventTimelineDescription}
        orderSchedule={orderSchedule}
        tagText={tagText}
        hideDatetime={hideDatetime}
        hideIcon={hideIcon}
      />
    } else if(eventDesc === 'Order Cancelled') {
      return <CustomTimelineItem
        key={key}
        dot={<Icon type="close-circle-o"/>}
        color={'red'}
        description={eventTimelineDescription}
        orderSchedule={orderSchedule}
        hideDatetime={hideDatetime}
        hideIcon={hideIcon}
      />
    } else if(eventDesc === 'Order Submitted') {
      return <CustomTimelineItem
        key={key}
        dot={<Icon type="check-circle-o"/>}
        color={iconGreen}
        description={eventTimelineDescription}
        orderSchedule={orderSchedule}
        hideDatetime={hideDatetime}
        hideIcon={hideIcon}
      />
    } else if(eventDesc.includes('Upload') || eventDesc.includes('Decline')) {
      return <CustomTimelineItem
        key={key}
        dot={<Icon type="check-circle-o"/>}
        color={isCompleted ? iconGreen : 'grey'}
        description={eventTimelineDescription}
        orderSchedule={orderSchedule}
        additionalInfo={additionalInfo}
        hideDatetime={hideDatetime}
        hideIcon={hideIcon}
      />
    } else if(reminder) {
      return <CustomTimelineItem
        key={key}
        dot={<Icon type="exclamation-circle"/>}
        color={'grey'}
        description={eventTimelineDescription}
        orderSchedule={orderSchedule}
        hideDatetime={hideDatetime}
        hideIcon={hideIcon}
      />
    } else {
      return <CustomTimelineItem
        key={key}
        dot={<Icon type="check-circle-o"/>}
        color={isCompleted ? iconGreen : 'grey'}
        description={eventTimelineDescription}
        orderSchedule={orderSchedule}
        hideDatetime={hideDatetime}
        hideIcon={hideIcon}
      />
    } */
}

const createAMCTimeline = (
  te,
  orderData,
  shouldGetReminders,
  shouldGetStatuses,
  shouldGetMessages,
  is_amc_lender,
  refreshOrder,
  eventIndex
) => {
  let eventDesc = te.event_desc
  let additionalInfo = te.additional_info
  const reminder = te.reminder
  let viewReasonText
  // eslint-disable-next-line no-unused-vars
  let messageStatus = false
  let tooltipPermission = false
  let hideTooltip = false
  const iconGreen = '#52C41B'
  let subText
  const format = 'MMM Do YYYY hh:mm a'
  let tagText
  let iconType = 'check-circle-o' // Default the icon type to check circle.
  let iconColor = iconGreen // Default the icon color to green
  if (te.created) {
    subText = utcToLocal(te.created).format(format)
  } else if (te.time) {
    subText = utcToLocal(te.time).format(format)
  }

  switch (te.notification_type) {
    case 'order_inspection_date_update':
      additionalInfo = utcToLocal(additionalInfo).format(format)
      viewReasonText = 'View Inspection Date'
      break

    // This case is the first time the inspection is scheduled (the status change)
    case 'order_status_change':
      if (eventDesc === 'Inspection Scheduled') {
        additionalInfo = utcToLocal(additionalInfo).format(format)
        viewReasonText = 'View Inspection Date'
      }
      break
    case 'order_inspection_scheduled':
      eventDesc = 'Inspection Scheduled'
      additionalInfo = utcToLocal(additionalInfo).format(format)
      viewReasonText = 'View Inspection Date'
      break
    case 'order_company_assignment_decline':
      viewReasonText = 'View Reason'
      iconType = 'exclamation-circle'
      iconColor = 'grey'
      break
    case 'order_canceled':
      iconType = 'close-circle-o'
      iconColor = 'red'
      if (orderData.has_been_reassigned) {
        eventDesc = 'Order Reassigned'
        iconType = 'exclamation-circle-o'
        iconColor = '#faad14'
        hideTooltip = true
        additionalInfo = 'reassigned'
        viewReasonText = (
          <span
            className="pointer"
            onClick={() => history.goBack()}
            id={additionalInfo}
          >
            {' '}
            Return to Active Order
          </span>
        )
      }
      break
    case 'order_reassigned':
      tooltipPermission = true
      if (additionalInfo) {
        hideTooltip = true
        viewReasonText = (
          <span
            className="pointer"
            onClick={() => history.push(`/orders/${additionalInfo}`)}
            id={additionalInfo}
          >
            {' '}
            View Reassigned Order
          </span>
        )
        /* viewReasonText = <span onClick={() => {
          history.replace({ pathname: `/orders/${additionalInfo}` });
          // refreshOrder()
        }}>
          View Reassigned Order
        </span> */
      }
      break
    case 'order_cancel_request':
      iconType = 'close-circle-o'
      iconColor = 'red'
      viewReasonText = 'View Reason'
      break
    case 'order_hold_change':
      iconType = 'exclamation-circle'
      iconColor = 'grey'
      break
    case 'order_placed_on_hold':
      iconType = 'exclamation-circle'
      iconColor = 'grey'
      viewReasonText = 'View Reason'
      break
    case 'initial_message':
      eventDesc = 'There are no messages for this order yet.'
      subText = "Type in the box below and click 'Send' to send a message."
      iconType = 'message'
      iconColor = 'grey'
      messageStatus = true
      break
    case 'Inspection Date':
      iconType = 'clock-circle-o'
      iconColor = 'red'
      eventDesc = 'Inspection Date'
      tagText = 'Behind Schedule'
      break
    case 'Order Due Date':
      iconType = 'clock-circle-o'
      iconColor = 'red'
      eventDesc = 'Order Due Date'
      tagText = 'Behind Schedule'
      break
    case 'order_company_assignment':
      viewReasonText = 'Assigned'
      tooltipPermission = true
      // We dont want to show appraiser name to users that dont have permission
      // (the permission check in CustomTimelineItem doesnt catch this event because this event is initiated by the lender)
      if (!checkCanViewAppraiserNameOnOrder(orderData)) {
        hideTooltip = true
        viewReasonText = null
      }
      break
    case 'broadcast_company_request':
      viewReasonText = 'Company Names'
      additionalInfo = (
        <ul>
          {additionalInfo.split('\n').map((name) => (
            <li
              key={name}
              className="p-0 mb-0"
              style={{ listStyleType: 'none' }}
            >
              {name}
            </li>
          ))}
        </ul>
      )
      tooltipPermission = true
      break
    case 'borrower_payment_refund_status_update':
      viewReasonText = 'Refund Status'
      if (additionalInfo && additionalInfo === 'failed') {
        iconType = 'exclamation-circle'
        iconColor = 'red'
      }
      break
    case 'appraiser_notified_of_payment':
      viewReasonText = 'View Message'
      break
    case 'lender_attention_resolved':
      viewReasonText = 'View Reasons'
      break
    case 'p_s_upload':
    case 'evault_upload':
    case 'amc_evault_upload':
      viewReasonText = 'Document Name'
      break
    case 'consumer_payment_error':
      iconType = 'exclamation-circle'
      iconColor = 'red'
      break
    case 'order_offer':
      subText = utcToLocal(te.created).format(format)
      break
    case 'consumer_submission_download_alerts':
      iconType = 'exclamation-circle'
      iconColor = 'red'
      if (additionalInfo) {
        viewReasonText = 'View Reason'
      }
      break
    case 'order_fee_escalation_denied':
    case 'order_due_date_denied':
    case 'order_fee_escalation_and_due_date_denied':
    case 'order_counter_denied':
      viewReasonText = 'More Details'
      iconType = 'exclamation-circle'
      iconColor = 'grey'
      break
    case 'order_fee_escalation_accepted':
    case 'order_due_date_accepted':
    case 'order_fee_escalation_and_due_date_accepted':
    case 'order_counter_approved':
      viewReasonText = 'More Details'
      break
    default:
      if (!eventDesc) {
        eventDesc = te.notification_type
      }
  }

  if (reminder && shouldGetReminders) {
    iconType = 'bell'
    iconColor = 'grey'
    return createTimelineEvent(
      eventDesc,
      subText,
      iconType,
      iconColor,
      tagText,
      additionalInfo,
      viewReasonText,
      te.initiator,
      te.initiator_type,
      tooltipPermission,
      hideTooltip,
      is_amc_lender,
      orderData.job_type === 'amc',
      orderData,
      eventIndex
    )
  }

  if (!reminder && shouldGetStatuses) {
    return createTimelineEvent(
      eventDesc,
      subText,
      iconType,
      iconColor,
      tagText,
      additionalInfo,
      viewReasonText,
      te.initiator,
      te.initiator_type,
      tooltipPermission,
      hideTooltip,
      is_amc_lender,
      orderData.job_type === 'amc',
      orderData,
      eventIndex
    )
  } else if (messageStatus && shouldGetMessages) {
    return createTimelineEvent(
      eventDesc,
      subText,
      iconType,
      iconColor,
      tagText,
      additionalInfo,
      viewReasonText,
      te.initiator,
      te.initiator_type,
      tooltipPermission,
      hideTooltip,
      is_amc_lender,
      orderData.job_type === 'amc',
      orderData,
      eventIndex
    )
  }
}

function dateSort(a, b) {
  if (moment(a.created).isAfter(b.created)) {
    return 1
  } else if (moment(a.created).isBefore(b.created)) {
    return -1
  } else {
    return 0
  }
}

function getDeliveryTimelineEvents(orderData, shouldBeVendorNeutral) {
  const buildingConsumerEvents = []

  if (orderData.submission_sent_to_consumer) {
    orderData.submission_sent_to_consumer.forEach((event) => {
      let name = ''
      const option = event.consumer
      if (option.full_name) {
        name = option.full_name
      } else {
        name = option.firstname + ' ' + option.lastname
      }
      const displayText = `Sent ${shouldBeVendorNeutral ? 'report' : 'appraisal'} to ${name}`
      buildingConsumerEvents.push({
        notification_type: displayText,
        created: event.time,
      })
    })
  }

  if (orderData.submission_downloaded) {
    orderData.submission_downloaded.forEach((event) => {
      let name = ''
      const option = event.consumer
      if (option.full_name) {
        name = option.full_name
      } else {
        name = option.firstname + ' ' + option.lastname
      }
      const displayText = `${shouldBeVendorNeutral ? 'Report' : 'Appraisal'} downloaded by ${name}`
      buildingConsumerEvents.push({
        notification_type: displayText,
        created: event.time,
      })
    })
  }

  return buildingConsumerEvents
}

function process_events(
  input_events,
  orderData,
  shouldGetReminders,
  shouldGetStatuses,
  shouldGetMessages,
  is_amc_lender,
  refreshOrder,
) {
  const final_events = []

  input_events.forEach((e, eventIndex) => {
    if (
      e.senderName ||
      e.id === 'current_vendor_message' ||
      e.id === 'current_vendor_messages'
    ) {
      final_events.push(e)
    } else {
      const timeline_event = createAMCTimeline(
        e,
        orderData,
        shouldGetReminders,
        shouldGetStatuses,
        shouldGetMessages,
        is_amc_lender,
        refreshOrder,
        eventIndex
      )

      if (timeline_event) {
        if (final_events.length) {
          if (Array.isArray(final_events[final_events.length - 1])) {
            final_events[final_events.length - 1].push(timeline_event)
          } else {
            final_events.push([timeline_event])
          }
        } else {
          final_events.push([timeline_event])
        }
      }
    }
  })

  return final_events
}

const shouldShowAppraiserOverrideWarning = (orderData) => {
  let showOverrideWarning = false
  let hasAppraiserOverrides = false
  let isFindingAppraisers = false
  const { statusKey, products } = orderData

  if (statusKey === 'finding_appraisers') {
    isFindingAppraisers = true
  }

  products.forEach((product) => {
    if (product.has_appraiser_overrides) {
      hasAppraiserOverrides = true
    }
  })

  if (hasAppraiserOverrides && isFindingAppraisers) {
    showOverrideWarning = true
  }

  return showOverrideWarning
}

const getNewMessageContainer = (
  orderData,
  props,
  shouldGetMessages,
  textToInsert,
  setTextToInsert,
  vendorMessages,
  cantMessage,
  primaryConversation) => {
  const {
    newMessage,
    is_amc_lender,
    onFocus,
    primaryConversationType,
    allowMessageParticipation,
    activeOrderTab,
    primaryOrderId,
    amc_conversation,
    conversation,
  } = props

  let conversationType = primaryConversationType
  let conversationId
  let conversationCompany

  if (primaryConversation && primaryConversation.company) {
    conversationCompany = primaryConversation.company
  }

  if (conversationType === 'amc_conversation') {
    conversationId = amc_conversation ? amc_conversation.id : null
  } else if (conversationType === 'conversation' || conversationType === 'unified_conversation') {
    conversationId = conversation ? conversation.id : null
  } else {
    conversationId = null
  }

  if (vendorMessages) {
    conversationType = 'current_vendor_messages'
    conversationId = null
  } else if (!allowMessageParticipation || !shouldGetMessages ||
    !primaryConversationType || !conversationId) {
    return null
  }
  // if user can't send messages then disable new message container and return empty element
  if (cantMessage) {
    return <></>
  } else {
    return (
      <NewMessageContainer
        conversation_id={conversationId}
        conversationType={conversationType}
        conversationCompany={conversationCompany}
        is_revision={false}
        modal={false}
        subOrderId={activeOrderTab !== primaryOrderId && activeOrderTab}
        textToInsert={textToInsert}
        resetTextToInsert={() => setTextToInsert(null)}
        newMessageTitle="Conversation"
        newMessage={newMessage}
        is_amc_lender={is_amc_lender}
        onFocus={onFocus}
        orderData={orderData}
      />
    )
  }
}

function OrderFeeDisplay({ orderData, amount }) {
  // Check if we're waiting for fee information
  const isFeePending = orderData.fields_awaiting_consumers && (
    orderData.fields_awaiting_consumers.includes('integration_fee') ||
    orderData.fields_awaiting_consumers.includes('order_created')
  )

  // If pending, show "Pending" message
  if (isFeePending) {
    return <div className="user-info__texr">Pending</div>
  }

  // Otherwise show the fee amount
  return (
    <div className="user-info__texr">
      ${amount.toFixed(2)}
    </div>
  )
}

class OrderDetailsTab extends Component {
  state = {
    filters: [],
    textToInsert: '',
  }

  setTextToInsert = (textToInsert) => {
    this.setState({ textToInsert })
  }

  onFilterClick = (filter, check) => {
    let new_filters = [...this.state.filters]

    if (!check) {
      new_filters.splice(new_filters.indexOf(filter), 1)
    } else {
      new_filters = [...new_filters, filter]
    }

    this.setState({
      filters: new_filters,
    })
  }

  clearFilters = () => {
    this.setState({
      filters: [],
    })
  }

  getAmcManagementFeeToDisplay = (amcManagementFee) => {
    if (amcManagementFee === 0) {
      return '$0.00'
    } else {
      return `$${amcManagementFee.toFixed(2)}`
    }
  }

  getPanelistFirmNames = (firmNames) => {
    return firmNames && Array.isArray(firmNames)
      ? (<div className='order-details-branch-name-popover'>
        {
          firmNames.map((firmName) => { return <div key={firmName}>{firmName}</div> })
        }
      </div>
      )
      : <></>
  }

  getBranchName = (branchData) => {
    return (<div className="user-info__texr">
      {checkPermission('branch_edit') ? (
        <Link to={`/branches/${branchData.id}`}>
          {branchData.name}
        </Link>
      ) : (
        branchData.name
      )}
    </div>)
  }

  render() {
    const {
      orderData,
      is_amc_lender,
      primaryMessages,
      pastAppraiserMessages,
      currentUserId,
      timelineData,
      timelineLoading,
      branchData,
      prepConversationMessages,
      refreshOrder,
      should_show_consumer_payment_style_field,
      is_review_appraiser,
      primaryMessagesBubbles,
      conversationStatus,
      primaryConversationType,
      allowMessageParticipation,
      onHide,
      amc_conversation,
      conversation,
      flags,
    } = this.props
    const { filters } = this.state

    const display_filters = primaryMessages && primaryMessages.length
      ? [MESSAGE_FILTER, STATUS_FILTER, REMINDER_FILTER]
      : [STATUS_FILTER, REMINDER_FILTER]

    const isPDR = isPdrOrder(orderData)
    const shouldBeVendorNeutral = isPDR || flags?.vendorNeutralTimelineEvents

    const activeAssignmentStatuses = new Set(['active', 'company_accepted', 'accepted'])
    const activeAssignments = orderData?.assignment_list.filter(a => activeAssignmentStatuses.has(a.status))
    const isUnifiedConversation = primaryConversationType === 'unified_conversation'
    let cantMessage
    if (primaryConversationType === 'amc_conversation' && orderData.job_type === 'amc') {
      // if the conversation type and the jobtype is amc allow messages from the begining
      cantMessage = false
    } else if (activeAssignments?.length === 1) {
      // timeline conversation can only be used when there is one active assignment
      // conversations with multiple companies are managed on the Appraisal Requests tab (i.e. broadcast)
      cantMessage = false
    } else if (!activeAssignments?.length && isUnifiedConversation) {
      // can send message on Unified Conversations event when no vendor active
      cantMessage = false
    } else if (!activeAssignments?.length && checkPermission('enable_current_vendor_message')) {
      // allow pending vendor messages if there are no assignments
      cantMessage = false
    } else {
      // dont allow messages
      cantMessage = true
    }

    let primaryConversation
    if (primaryConversationType === 'amc_conversation') {
      primaryConversation = amc_conversation || null
    } else if (primaryConversationType === 'conversation' || isUnifiedConversation) {
      primaryConversation = conversation || null
    } else {
      primaryConversation = null
    }

    let forms = ''
    if (orderData && orderData.products) {
      orderData.products.forEach((product) => {
        if (product.included_forms) {
          product.included_forms.forEach((form) => {
            forms += `${forms.length ? ', ' : ''}${FORM_KEYS_TO_READABLE[form]}`
          })
        }
        if (product.unsupported_forms) {
          forms += `${forms.length ? ', ' : ''}${product.unsupported_forms}`
        }
      })
    }

    let shouldGetMessages = true
    let shouldGetReminders = true
    let shouldGetStatuses = true
    const shouldGetVendorNotes = true
    const vendorMessages =
      orderData &&
      orderData.statusKey === 'waiting_for_payment' &&
      checkPermission('enable_current_vendor_message') &&
      orderData.job_type !== 'amc' &&
      !isUnifiedConversation
    // If we have filters, only get the things that are in the filter.
    // TODO: How do we classify the consumer event with the filters?
    if (filters.length) {
      if (filters.indexOf(MESSAGE_FILTER) === -1) {
        shouldGetMessages = false
      }
      if (filters.indexOf(REMINDER_FILTER) === -1) {
        shouldGetReminders = false
      }
      if (filters.indexOf(STATUS_FILTER) === -1) {
        shouldGetStatuses = false
      }
    }

    // TODO: There is still something wrong with this in the context of AMC, test to make sure that it works for the amc lender and
    // nom amc lender.
    let feed_messages = []

    if (shouldGetMessages && primaryMessages && primaryMessages.length) {
      feed_messages = primaryMessages
    }

    if (
      shouldGetMessages &&
      pastAppraiserMessages &&
      pastAppraiserMessages.length
    ) {
      feed_messages = [...feed_messages, ...pastAppraiserMessages]
    }

    // Create waiting for payment current_vendor_messages
    if (
      shouldGetVendorNotes &&
      orderData.current_vendor_messages &&
      orderData.current_vendor_messages.length
    ) {
      const isPastAppraiserConversation = false

      const m = prepConversationMessages(
        orderData.current_vendor_messages,
        currentUserId,
        'lender',
        isPastAppraiserConversation,
        shouldGetVendorNotes,
        orderData,
      )
      const current_vendor_messages = m.messages

      feed_messages = [...feed_messages, ...current_vendor_messages]
    }

    let timelineEvents = []
    if (timelineData) {
      timelineEvents = timelineData
    }

    if (orderData && !is_amc_lender) {
      timelineEvents = [
        ...timelineEvents,
        ...getDeliveryTimelineEvents(orderData, shouldBeVendorNeutral),
      ]
    }

    // Grab all timeline items and sort them by date
    let total_chat_timeline = []
    if (timelineEvents) {
      total_chat_timeline = [...feed_messages, ...timelineEvents]
    } else {
      total_chat_timeline = [...feed_messages]
    }

    total_chat_timeline.sort(dateSort)

    // If we have an array for messages, then that means it was initialized from a converation,
    // but we dont actually have any messages.
    if (
      shouldGetMessages &&
      Array.isArray(primaryMessages) &&
      !primaryMessages.length
    ) {
      total_chat_timeline.push({
        notification_type: 'initial_message',
      })
    }

    // Process all of the OrderTimeline Items (chat, events, vendor notes/messages)
    total_chat_timeline = process_events(
      total_chat_timeline,
      orderData,
      shouldGetReminders,
      shouldGetStatuses,
      shouldGetMessages,
      is_amc_lender,
      refreshOrder,
    )

    // Branch name section
    let displayBranchName
    if (branchData && branchData.name) {
      if (orderData && is_amc_lender && 'branch_preferred_panel' in orderData) {
        // Preferred panel info for AMP users
        const panelistFirmNames = this.getPanelistFirmNames(orderData.branch_preferred_panelist_firm_names)
        displayBranchName = (<div className="user-info">
          <div className="user-info__heading">Branch Name:<span className='text-danger'>*</span></div>
          <Popover
            content={panelistFirmNames}
            title={`Preferred Panel Strict: ${orderData.branch_preferred_panel.panel_strict ? 'True' : 'False'}`}
            trigger="hover">
            {this.getBranchName(branchData)}
          </Popover>
        </div>)
      } else {
        displayBranchName = (<div className="user-info">
          <div className="user-info__heading">Branch Name:</div>
          {this.getBranchName(branchData)}
        </div>)
      }
    }

    if (!orderData) {
      return <div>Loading</div>
    }

    const reggora_amc_management_fee = orderData.reggora_amc_management_fee_dollars === null
      ? 0
      : orderData.reggora_amc_management_fee_dollars

    const hideRequestedAppraiser = () => {
      return !is_amc_lender && orderData.job_type === 'amc' && orderData.order_request_method === 'broadcast'
    }

    const getRequestedAppraiserElement = () => {
      if (orderData.job_type === 'amc' && orderData.order_request_method !== 'broadcast' && !is_amc_lender) {
        // If we are dealing with an amc order, then we only actually want to show the requested appraiser if the user is the amc lender.
        return 'Reggora Network'
      }
      if (orderData.job_type === 'amc' && orderData.order_request_method === 'individually' && is_amc_lender && orderData.requested && orderData.requested[0]) {
        return orderData.requested[0].panelist_name
      }
      if (orderData.job_type !== 'amc' && orderData.order_request_method === 'individually' && orderData.requested && orderData.requested[0]) {
        return orderData.requested[0].panelist_name
      }
      if (orderData.job_type !== 'amc' && orderData.order_request_method === 'individually' && orderData.requested && orderData.requested?.length === 0) {
        return <Rephrase>Calculating Appraiser</Rephrase>
      }
      if (orderData.order_request_method === 'broadcast') {
        return 'Broadcast'
      }
      if (orderData.order_request_method === 'cascade') {
        return 'Cascade'
      }

      return <></>
    }

    return (
      <Fragment>
        <div className="d-flex">
          <div className="details-container p-4">
            <h4 className="lead">
              <ProfileOutlined /> Order Details
            </h4>

            <div className="user-info">
              <div className="user-info__heading">Status:</div>
              <div className="user-info__texr">
                {getOrderStatus(orderData, is_amc_lender)}
              </div>
            </div>

            {orderData.va_lin && (
              <div className="user-info">
                <div className="user-info__heading">VA Portal LIN:</div>
                <div className="user-info__texr">{orderData.va_lin}</div>
              </div>
            )}

            {!['va', 'review'].includes(orderData.job_type) && (
              <Fragment>
                <div className="user-info">
                  <div className="user-info__heading">{is_amc_lender ? 'Lender ' : ''}Due Date:</div>
                  <div className="user-info__texr">
                    {getUTCTime(orderData.lender_due_date || orderData.due_date)}
                  </div>
                </div>
                {is_amc_lender && (
                  <div className="user-info">
                    <div className="user-info__heading">Appraiser Due Date:</div>
                    <div className="user-info__texr">
                      {getUTCTime(orderData.due_date)}
                    </div>
                  </div>
                )}
              </Fragment>
            )}

            {orderData.inspection_date && (
              <div className="user-info">
                <div className="user-info__heading">Inspection Date:</div>
                <div className="user-info__texr">{utcToLocal(orderData.inspection_date).format('LLLL')}</div>
              </div>)}

            {orderData.statusKey === 'finding_appraisers' && checkCanViewAppraiserNameOnOrder(orderData) && (
              <div className="user-info">
                {hideRequestedAppraiser() ? <></>
                  : <>
                    <div className="user-info__heading"><Rephrase>Requested Appraiser:</Rephrase></div>
                    <div className="user-info__texr"> {getRequestedAppraiserElement()}</div>
                  </>
                }
              </div>
            )}
            {['inspection_scheduled', 'inspection_completed', 'submitted'].indexOf(orderData.statusKey) > -1 && orderData.accepted && checkCanViewAppraiserNameOnOrder(orderData) ? (<div className="user-info">
              <div className="user-info__heading"><Rephrase>Appraiser:</Rephrase></div>
              <div className='user_info__texr'>{orderData.accepted.panelist_name}</div>
            </div>) : null}
            <div className="user-info">
              <div className="user-info__heading">Order Type:</div>
              <div className="user-info__texr">{orderData.priority}</div>
            </div>
            <div className="user-info">
              <div className="user-info__heading">Product Names:</div>
              <div className="product-details">
                {orderData.products &&
                  orderData.products.map((product) => {
                    return (
                      <div key={product.id} className="user-info__texr">
                        <li className="product-name">{product.description}</li>
                      </div>
                    )
                  })}
              </div>
            </div>
            {should_show_consumer_payment_style_field &&
              orderData.job_type !== 'review' && (
              <div className="user-info">
                <div className="user-info__heading">
                    Borrower Payment Option:
                </div>
                <div className="user-info__texr user-info-longText">
                  <div className="user-info__texr">
                    {
                      paymentOptionsToReadable[
                        orderData.consumer_payment_style
                      ]
                    }
                  </div>
                </div>
              </div>
            )}
            {Boolean(orderData.order_fee_for_lender) &&
              !is_review_appraiser &&
              orderData.job_type !== 'review' && (
              <>
                {/* Show Total Fee on the top for AMC Lender only */}
                {is_amc_lender && (
                  <div className="user-info">
                    <div className="user-info__heading">
                        Total Fee:
                    </div>
                    <OrderFeeDisplay
                      orderData={orderData}
                      amount={orderData.total_lender_fee}
                    />
                    {shouldShowAppraiserOverrideWarning(orderData) && (
                      <Popover
                        content="This order has one or more products with vendor level pricing associated with it. Please note that the fee may vary depending on which vendor accepts the order."
                        overlayStyle={{ width: 350 }}
                      >
                        <span className="fee-warning ml-2 pl-2 pr-2">
                          <i className="fas fa-exclamation-circle exlamation-color mr-1"></i>
                              Fee may vary
                        </span>
                      </Popover>
                    )}
                  </div>
                )}
                {/* Show Base Order Fee for non AMC lender */}
                {!is_amc_lender && (
                  <div className="user-info">
                    <div className="user-info__heading">
                        Order Fee:
                    </div>
                    <OrderFeeDisplay
                      orderData={orderData}
                      amount={orderData.order_fee_for_lender}
                    />
                    {shouldShowAppraiserOverrideWarning(orderData) && (
                      <Popover
                        content="This order has one or more products with vendor level pricing associated with it. Please note that the fee may vary depending on which vendor accepts the order."
                        overlayStyle={{ width: 350 }}
                      >
                        <span className="fee-warning ml-2 pl-2 pr-2">
                          <i className="fas fa-exclamation-circle exlamation-color mr-1"></i>
                              Fee may vary
                        </span>
                      </Popover>
                    )}
                  </div>
                )}
              </>
            )}
            {/* Show Appraiser Fee and AMC Fee for AMC lender */}
            {is_amc_lender && !is_review_appraiser ? (
              <Fragment>
                <div className="user-info">
                  <div className="user-info__heading">Appraiser Fee:</div>
                  <div className="user-info__texr">${orderData.total_appraiser_fee.toFixed(2)}</div>
                </div>
                <div className="user-info">
                  <div className="user-info__heading" data-testid='amcManagementFeeHeader'>
                    AMC Fee:
                  </div>
                  <div className="user-info__texr" data-testid='amcManagementFeeBody'>
                    {orderData.is_reggora_amc_order_with_standard_products &&
                    this.getAmcManagementFeeToDisplay(reggora_amc_management_fee)}
                    {!!orderData.reggora_fee && `$${orderData.reggora_fee.toFixed(2)}`}
                  </div>
                  {orderData.is_reggora_amc_order_with_standard_products &&
                    <ModalTrigger
                      modalClassName='modal-primary'
                      component={EditAMCManagementFeeModal}
                      totalFee={orderData.total_lender_fee}
                      appraiserFee={orderData.total_appraiser_fee}
                      amcManagementFee={reggora_amc_management_fee}
                      orderId={orderData.id}
                      refreshOrder={refreshOrder}
                    >
                      <i
                        id='priority-popover'
                        className='edit-icon far fa-edit margin-top-3'
                        data-testid='amcManagementFeeEditIcon' />
                    </ModalTrigger>}
                  {!orderData.is_reggora_amc_order_with_standard_products &&
                    <span className='ml-2'><i>Editing mgt fee is disabled for legacy AMC flow</i></span>
                  }
                </div>
              </Fragment>
            ) : null}
            {/* Show Additional Fees */}
            {orderData.additional_fees &&
              !is_review_appraiser &&
              [...orderData.additional_fees.map((fee, index) => {
                return (
                  <div className="user-info" key={`additional-fee-${fee.id || index}`}>
                    <div className="user-info__heading">Additional Fee:</div>
                    <div className="user-info__texr">
                      {formatCurrency(fee.amount)} - {fee.description}
                    </div>
                  </div>
                )
              }),
              ...orderData.order_fees.map((fee, index) => {
                return (
                  <div className="user-info" key={`order-fee-${fee.id || index}`}>
                    <div className="user-info__heading">Additional Fee:</div>
                    <div className="user-info__texr">
                      {formatCurrency(fee.fee_amount / 100)} - {fee.fee_description}
                    </div>
                  </div>
                )
              }),
              ]
            }
            {/* Show Total Fee at bottom for non-AMC lender only when there are additional fees */}
            {!is_amc_lender &&
              !is_review_appraiser &&
              orderData.job_type !== 'review' &&
              ((orderData.additional_fees && orderData.additional_fees.length > 0) ||
               (orderData.order_fees && orderData.order_fees.length > 0)) && (
              <div className="user-info">
                <div className="user-info__heading">
                    Total Fee:
                </div>
                <OrderFeeDisplay
                  orderData={orderData}
                  amount={orderData.total_lender_fee}
                />
              </div>
            )}
            <div className="user-info">
              <div className="user-info__heading">Forms:</div>
              <div className="user-info__texr">{forms}</div>
            </div>
            {orderData.status === 'Done' && (
              <div className="user-info">
                <div className="user-info__heading">
                  <Rephrase>Sent Appraisal To Consumer:</Rephrase>
                </div>
                {orderData.sent_to_consumer &&
                  orderData.sent_to_consumer.map((consumer, index) => (
                    <div className="user-info__texr" key={index}>
                      {consumer.name} Sent{' '}
                      {utcToLocal(consumer.time).format('MMM Do, YYYY')}
                    </div>
                  ))}
              </div>
            )}
            {is_amc_lender && orderData.lender && (
              <div className="user-info">
                <div className="user-info__heading">Lender:</div>
                <div className="user-info__texr">{orderData.lender.name}</div>
              </div>
            )}
            {displayBranchName}
            {is_amc_lender && (
              <>
                <div className="user-info">
                  <div className="user-info__heading">RUCC Code:</div>
                  <div className="user-info__texr">{this.props.ruccCode}</div>
                </div>
                <div className="user-info">
                  <div className="user-info__heading">RUCC Classification:</div>
                  <div className="user-info__texr">{getRUCCTypeFromCode(this.props.ruccCode)}</div>
                </div>
              </>
            )}
            <div className="user-info">
              <div className="user-info__heading pt-2">Assigned:</div>
              {orderData.fields_awaiting_consumers &&
                orderData.fields_awaiting_consumers.includes(
                  'order_created',
                ) && <div className="user-info__texr">Pending</div>}
              <div className="user-info__text">
                {(!orderData.fields_awaiting_consumers ||
                  !orderData.fields_awaiting_consumers.includes(
                    'order_created',
                  )) &&
                  buildAvatars(orderData.assigned)}
                {checkPermission('order_edit_assigned_users') &&
                (!orderData.fields_awaiting_consumers ||
                  !orderData.fields_awaiting_consumers.includes(
                    'order_created',
                  )) && ( // Only show the edit processors to lender admins, and dont show it to the AMC
                  <ModalTrigger
                    component={EditAssignedModal}
                    orderData={orderData}
                    id={orderData.id}
                    refreshOrder={refreshOrder}
                  >
                    <Button color="light">Edit</Button>
                  </ModalTrigger>
                )}
              </div>
            </div>
          </div>
          {checkPermission('notes_view') && orderData ? (
            <div className="internal-note-container p-4">
              <h4 className="lead">
                <FormOutlined /> Internal Notes
              </h4>
              <Notes
                notes={orderData.notes}
                currentUserId={currentUserId}
                orderId={orderData.id}
                refreshOrder={refreshOrder}
              />
            </div>
          ) : (
            <div className="notes-container-placeholder w-50"></div>
          )}
        </div>
        <div className="pt-0 pb-4 px-4">
          <h4 className="lead">
            <BarsOutlined /> Order Timeline
          </h4>
          <div className="d-flex flex-column align-content-between">
            <div className="chat-controls">
              {primaryMessagesBubbles && (
                <div className="chat-avatars">
                  {primaryMessagesBubbles}
                </div>
              )}
              <div className="filters">
                <h6 className="filter-header">Filters:</h6>
                {display_filters.map((tag) => (
                  <CheckableTag
                    className=""
                    key={tag}
                    checked={filters.indexOf(tag) > -1}
                    onChange={(checked) => this.onFilterClick(tag, checked)}
                  >
                    {tag}
                  </CheckableTag>
                ))}
                {filters.length > 0 && (
                  <span className="filter-clear" onClick={this.clearFilters}>
                    Clear
                  </span>
                )}
              </div>
            </div>

            <div className="grey-divider"></div>

            {timelineData &&
            !timelineLoading &&
            conversationStatus !== 'loading' ? (
                <ChatWrapper
                  total_chat_timeline={total_chat_timeline}
                  timelineData={timelineData}
                  isUnifiedConversation={isUnifiedConversation}
                  isAMPUser={is_amc_lender}
                />
              ) : (
                <SmallLoader />
              )
            }

            {(primaryConversationType || vendorMessages) &&
              getNewMessageContainer(
                orderData,
                this.props,
                shouldGetMessages,
                this.state.textToInsert,
                this.setTextToInsert,
                vendorMessages,
                cantMessage,
                primaryConversation
              )}

            <UnansweredMessageInfo
              primaryConversation={primaryConversation || {}}
              is_amc_lender={is_amc_lender}
              shouldGetMessages={shouldGetMessages}
            />

            {allowMessageParticipation &&
              shouldGetMessages &&
              primaryConversationType &&
              primaryConversation &&
              !orderData.has_been_reassigned && (
              <CommentTemplateDropdown
                name={'order-messages-templates-list'}
                onHide={onHide}
                refreshOrder={refreshOrder}
                setTextToInsert={this.setTextToInsert}
              />
            )}
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withLDConsumer()(OrderDetailsTab)
