import { useCallback, useState } from 'react'

const renderPageToImage = async (page, width, height) => {
  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height
  const context = canvas.getContext('2d')
  await page.render({ canvasContext: context, viewport: page.getViewport({ scale: 1.0 }) }).promise

  return context.getImageData(0, 0, canvas.width, canvas.height)
}

export const useCompareGraphics = () => {
  const [progress, setProgress] = useState(0)
  const [isProcessing, setIsProcessing] = useState(true) // For showing overlay during processing

  // Compare graphics (e.g., checkboxes)
  const compareGraphics = useCallback(async (pdfDoc1, pdfDoc2, alignedPages) => {
    const diffs = []
    const CHECKBOX_TOLERANCE = 5
    const MIN_AREA = 40 // Define the minimum area threshold (adjust as needed)
    let diffIndex = 0

    for (let index = 0; index < alignedPages.length; index++) {
      const aligned = alignedPages[index]

      if (aligned.type === 'matched') {
        const pageNum1 = aligned.pageNum1
        const pageNum2 = aligned.pageNum2

        setProgress(Math.round(((index + 1) / alignedPages.length) * 100)) // Update progress

        if (pageNum1 && pageNum2) {
          const page1 = await pdfDoc1.getPage(pageNum1)
          const page2 = await pdfDoc2.getPage(pageNum2)

          const viewport1 = page1.getViewport({ scale: 1.0 })
          const viewport2 = page2.getViewport({ scale: 1.0 })

          const maxWidth = Math.max(viewport1.width, viewport2.width)
          const maxHeight = Math.max(viewport1.height, viewport2.height)

          const [page1Img, page2Img] = await Promise.all([
            renderPageToImage(page1, maxWidth, maxHeight),
            renderPageToImage(page2, maxWidth, maxHeight),
          ])

          if (page1Img && page2Img) {
            const pixelVisited = Array.from({ length: Math.ceil(maxHeight) }, () =>
              Array.from({ length: Math.ceil(maxWidth) }, () => false)
            )

            const isRemovedPixel = (x, y) => {
              const idx = (y * maxWidth + x) * 4
              return (
                (page1Img.data[idx] !== 0 || page1Img.data[idx + 1] !== 0 || page1Img.data[idx + 2] !== 0) &&
                (page2Img.data[idx] === 0 && page2Img.data[idx + 1] === 0 && page2Img.data[idx + 2] === 0)
              )
            }

            const isAddedPixel = (x, y) => {
              const idx = (y * maxWidth + x) * 4
              return (
                (page2Img.data[idx] !== 0 || page2Img.data[idx + 1] !== 0 || page2Img.data[idx + 2] !== 0) &&
                (page1Img.data[idx] === 0 && page1Img.data[idx + 1] === 0 && page1Img.data[idx + 2] === 0)
              )
            }

            const groupPixels = (startX, startY, type) => {
              const queue = [[startX, startY]]
              let minX = startX; let maxX = startX; let minY = startY; let maxY = startY
              let pixelCount = 0 // Count the number of pixels in the group

              while (queue.length > 0) {
                const [x, y] = queue.shift()

                if (x < 0 || x >= maxWidth || y < 0 || y >= maxHeight || pixelVisited[y][x]) { continue }

                const isDiff = type === 'removed' ? isRemovedPixel(x, y) : isAddedPixel(x, y)
                if (!isDiff) { continue }

                pixelVisited[y][x] = true
                pixelCount++

                minX = Math.min(minX, x)
                maxX = Math.max(maxX, x)
                minY = Math.min(minY, y)
                maxY = Math.max(maxY, y)

                for (let dx = -CHECKBOX_TOLERANCE; dx <= CHECKBOX_TOLERANCE; dx++) {
                  for (let dy = -CHECKBOX_TOLERANCE; dy <= CHECKBOX_TOLERANCE; dy++) {
                    const newX = x + dx
                    const newY = y + dy
                    if (newX >= 0 && newX < maxWidth && newY >= 0 && newY < maxHeight) {
                      queue.push([newX, newY])
                    }
                  }
                }
              }

              return { minX, maxX, minY, maxY, type, pixelCount }
            }

            for (let y = 0; y < maxHeight; y++) {
              for (let x = 0; x < maxWidth; x++) {
                if (!pixelVisited[y][x] && isAddedPixel(x, y)) {
                  const { minX, maxX, minY, maxY, type } = groupPixels(x, y, 'added')
                  const area = (maxX - minX + 1) * (maxY - minY + 1)

                  if (area >= MIN_AREA) {
                    diffs.push({
                      id: `graphic-diff-${index}-${diffIndex++}`,
                      x: minX,
                      y: minY,
                      width: maxX - minX + 1,
                      height: maxY - minY + 1,
                      page: index + 1,
                      type,
                    })
                  }
                }
              }
            }

            for (let y = 0; y < maxHeight; y++) {
              for (let x = 0; x < maxWidth; x++) {
                if (!pixelVisited[y][x] && isRemovedPixel(x, y)) {
                  const { minX, maxX, minY, maxY, type } = groupPixels(x, y, 'removed')
                  const area = (maxX - minX + 1) * (maxY - minY + 1)

                  if (area >= MIN_AREA) {
                    diffs.push({
                      id: `graphic-diff-${index}-${diffIndex++}`,
                      x: minX,
                      y: minY,
                      width: maxX - minX + 1,
                      height: maxY - minY + 1,
                      page: index + 1,
                      type,
                    })
                  }
                }
              }
            }
          }
        }
      } else {
        // Handle added or removed pages
        setProgress(Math.round(((index + 1) / alignedPages.length) * 100)) // Update progress
      }
    }

    setIsProcessing(false) // Hide progress bar when done
    return diffs
  }, [])

  return { compareGraphics, progress, isProcessing }
}
