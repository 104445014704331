// Align pages based on similarity
export const alignPages = (elements1, elements2) => {
  const similarityThreshold = 0.5 // Adjust as needed
  const matchedPages = comparePagesAndMatch(elements1, elements2, similarityThreshold)
  const aligned = []
  const usedPages1 = new Set()
  const usedPages2 = new Set()

  matchedPages.forEach(match => {
    if (match.page1 !== null && match.page2 !== null) {
      aligned.push({
        type: 'matched',
        elements1: elements1[match.page1 - 1],
        elements2: elements2[match.page2 - 1],
        pageNum1: match.page1,
        pageNum2: match.page2,
        similarity: match.similarity,
      })
      usedPages1.add(match.page1 - 1)
      usedPages2.add(match.page2 - 1)
    } else if (match.page1 !== null) {
      aligned.push({
        type: 'removed',
        elements1: elements1[match.page1 - 1],
        elements2: [],
        pageNum1: match.page1,
        pageNum2: null,
        similarity: match.similarity,
      })
      usedPages1.add(match.page1 - 1)
    } else if (match.page2 !== null) {
      aligned.push({
        type: 'added',
        elements1: [],
        elements2: elements2[match.page2 - 1],
        pageNum1: null,
        pageNum2: match.page2,
        similarity: match.similarity,
      })
      usedPages2.add(match.page2 - 1)
    }
  })

  // Handle any pages in pdf1 that weren't matched
  elements1.forEach((pageElements1, index1) => {
    if (!usedPages1.has(index1)) {
      aligned.push({
        type: 'removed',
        elements1: pageElements1,
        elements2: [],
        pageNum1: index1 + 1,
        pageNum2: null,
        similarity: 0,
      })
    }
  })

  // Handle any pages in pdf2 that weren't matched
  elements2.forEach((pageElements2, index2) => {
    if (!usedPages2.has(index2)) {
      aligned.push({
        type: 'added',
        elements1: [],
        elements2: pageElements2,
        pageNum1: null,
        pageNum2: index2 + 1,
        similarity: 0,
      })
    }
  })

  // Sort aligned pages based on page numbers to maintain order
  aligned.sort((a, b) => {
    const pageNumA = a.pageNum1 !== null ? a.pageNum1 : a.pageNum2
    const pageNumB = b.pageNum1 !== null ? b.pageNum1 : b.pageNum2
    return pageNumA - pageNumB
  })

  return aligned
}

// Compare pages and match them based on similarity
const comparePagesAndMatch = (elements1, elements2, tolerance = 0.5) => {
  const matchedPages = []

  // Match pages from PDF 1 to PDF 2
  const matchedPages2 = new Set()
  for (let i = 0; i < elements1.length; i++) {
    const pageElements1 = elements1[i]
    let bestMatch = null
    let bestSimilarity = -1

    for (let j = 0; j < elements2.length; j++) {
      if (matchedPages2.has(j)) { continue }

      const pageElements2 = elements2[j]
      const similarity = calculateElementSimilarity(pageElements1, pageElements2)

      if (similarity > bestSimilarity) {
        bestSimilarity = similarity
        bestMatch = j + 1 // Pages are 1-based
      }
    }

    if (bestSimilarity >= tolerance) {
      matchedPages.push({ page1: i + 1, page2: bestMatch, similarity: bestSimilarity })
      matchedPages2.add(bestMatch - 1)
    } else {
      matchedPages.push({ page1: i + 1, page2: null, similarity: bestSimilarity })
    }
  }

  // Match pages from PDF 2 to PDF 1
  for (let j = 0; j < elements2.length; j++) {
    if (matchedPages2.has(j)) { continue }

    const pageElements2 = elements2[j]
    let bestSimilarity = -1

    for (let i = 0; i < elements1.length; i++) {
      const pageElements1 = elements1[i]
      const similarity = calculateElementSimilarity(pageElements1, pageElements2)

      if (similarity > bestSimilarity) {
        bestSimilarity = similarity
      }
    }

    if (bestSimilarity >= tolerance) {
      // Already matched in previous step
    } else {
      matchedPages.push({ page1: null, page2: j + 1, similarity: bestSimilarity })
    }
  }

  return matchedPages
}

// Calculate similarity between two pages
const calculateElementSimilarity = (elements1, elements2) => {
  const totalElements = Math.max(elements1.length, elements2.length)
  if (totalElements === 0) { return 1 } // If both pages are empty, they are identical

  let matchingElements = 0

  elements1.forEach(el1 => {
    const matchingElement = elements2.find(el2 => el1.str === el2.str && arePositionsSimilar(el1, el2))
    if (matchingElement) {
      matchingElements++
    }
  })

  return matchingElements / totalElements
}

const arePositionsSimilar = (el1, el2, tolerance = 15) => {
  const [x1, y1] = el1.transform || [0, 0]
  const [x2, y2] = el2.transform || [0, 0]
  return Math.abs(x1 - x2) <= tolerance && Math.abs(y1 - y2) <= tolerance
}
