import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useApiRequest } from '../../../common/hooks'
import { selectIsSubmissionDataIncomplete } from '../../../store/reducers/order/orderSelectors'
import { updateOrder } from '../../../store/reducers/order/orderSlice'
import SubmissionObject from './SubmissionObject'
import { SmallLoader } from '../../../common/layout/components/Loader'
import {
  fetchSubmissionReviews,
} from '../../../../app/store/reducers/submissionReviews/submissionReviewsSlice'
import {
  fetchSubmissionFiles,
} from '../../../../app/store/reducers/submissionFiles/submissionFilesSlice'
import { useFlags } from 'launchdarkly-react-client-sdk'


const SubmissionsTab = (props) => {
  const {
    orderData,
    loanData,
    is_amc_lender,
    lenderData,
    actions,
    send_borrower_appraisal,
    auto_send_borrower_appraisal,
    auto_send_borrower_appraisal_delay,
    review_appraisal_hours_start,
    review_appraisal_hours_end,
    order,
    currentUserId,
    refreshOrder,
    is_review_appraiser,
    onHide,
    canSeeSubmissionTab,
  } = props

  const [isLoading, setIsLoading] = useState(false)
  const apiRequest = useApiRequest()
  const dispatch = useDispatch()
  const isSubmissionDataIncomplete = useSelector(selectIsSubmissionDataIncomplete)
  const { compareAppraisalPdfs, viewWarrantyReviews } = useFlags()
  const orderId = orderData.id

  const fetchOrderSubmissionReviews = useCallback(
    (orderId, is_amc_lender, viewWarrantyReviews) => {
      dispatch(fetchSubmissionReviews({ orderId, is_amc_lender, viewWarrantyReviews }))
    },
    [dispatch],
  )

  const fetchOrderSubmissionFiles = useCallback(
    (orderId) => {
      dispatch(fetchSubmissionFiles(orderId))
    },
    [dispatch],
  )

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)

      try {
        const submissionsResponse = await apiRequest({
          endpoint: `order/submissions?order_id=${orderId}`,
          method: 'GET',
        })
        if (orderData?.review_engine?.enabled || is_amc_lender) {
          fetchOrderSubmissionReviews(orderId, is_amc_lender, viewWarrantyReviews)
          fetchOrderSubmissionFiles(orderId)
        }
        const update = {
          submissions: submissionsResponse.map((submission) => {
            return {
              ...submission,
              isIncomplete: false,
            }
          }),
        }
        dispatch(updateOrder({ orderID: orderId, update }))
        setIsLoading(false)
      } catch {
        setIsLoading(false)
      }
    }

    if (isSubmissionDataIncomplete) {
      fetchData()
    }
  }, [apiRequest, dispatch,
    isSubmissionDataIncomplete, orderId,
    fetchOrderSubmissionReviews, fetchOrderSubmissionFiles,
    orderData?.review_engine?.enabled, is_amc_lender, viewWarrantyReviews])

  if (isLoading) {
    return <SmallLoader />
  }

  const orderedSubmissions = orderData.submissions.slice().reverse()

  return (
    <div>
      {orderedSubmissions.map((sub, index) => {
        const previousSubmission = sub.version > 1 && index < orderedSubmissions.length - 1
          ? orderedSubmissions[index + 1]
          : undefined
        const hasSubmittedToGse =
          loanData.ucdp_submissions &&
          loanData.ucdp_submissions.length > 0
        let submissionIndex = 0
        const which_gses = []
        // ucdp submission
        let hasUcdpSubmission = false
        let ucdpSubmission = null
        let ucdpAppraisalIndex = null
        let ucdpSubmissionIndex = null
        let ucdpMostRecentIndex = null
        let isMostRecentUcdpSubmission = false
        // ead submission
        let hasEadSubmission = false
        let eadSubmission = null
        let eadAppraisalIndex = null
        let eadSubmissionIndex = null
        let eadMostRecentIndex = null
        let isMostRecentEadSubmission = false

        if (hasSubmittedToGse) {
          for (const submission of loanData.ucdp_submissions) {
            if (submission.pathway_result.status === 'testing') {
              // skip any testing results
              submissionIndex++
              continue
            }
            // keep track of most recent submission to UCDP and EAD
            if (submission.which_gse === 'UCDP') {
              ucdpMostRecentIndex = submissionIndex
            } else if (submission.which_gse === 'EAD') {
              eadMostRecentIndex = submissionIndex
            }
            // determine if GSE submission matches order submission version
            let appraisalIndex = 0
            for (const submittedAppraisal of submission.pathway_request
              .appraisals) {
              if (
                sub.version === submittedAppraisal.submission_version &&
                orderData.id === submittedAppraisal.order_id
              ) {
                which_gses.push(submission.which_gse)
                if (submission.which_gse === 'UCDP') {
                  hasUcdpSubmission = true
                  ucdpSubmission = submission
                  ucdpAppraisalIndex = appraisalIndex
                  ucdpSubmissionIndex = submissionIndex
                } else if (submission.which_gse === 'EAD') {
                  hasEadSubmission = true
                  eadSubmission = submission
                  eadAppraisalIndex = appraisalIndex
                  eadSubmissionIndex = submissionIndex
                }
              }
              appraisalIndex++
            }
            submissionIndex++
          }
        }

        // determine if the matching submission is the most recent submission
        if (ucdpSubmissionIndex !== null && ucdpMostRecentIndex !== null) {
          isMostRecentUcdpSubmission = ucdpSubmissionIndex === ucdpMostRecentIndex
        }
        if (eadSubmissionIndex !== null && eadMostRecentIndex !== null) {
          isMostRecentEadSubmission = eadSubmissionIndex === eadMostRecentIndex
        }

        if (canSeeSubmissionTab) {
          return (
            <SubmissionObject
              key={sub.version}
              loanData={loanData}
              orderId={orderData.id}
              orderData={orderData}
              sub={sub}
              previousSubmission={previousSubmission}
              isMostRecentSubmission={index === 0}
              loanHasUcdpSubmission={hasSubmittedToGse}
              // ucdp submission
              hasUcdpSubmission={hasUcdpSubmission}
              ucdpSubmission={ucdpSubmission}
              ucdpAppraisalIndex={ucdpAppraisalIndex}
              isMostRecentUcdpSubmission={isMostRecentUcdpSubmission}
              // ead submission
              hasEadSubmission={hasEadSubmission}
              eadSubmission={eadSubmission}
              eadAppraisalIndex={eadAppraisalIndex}
              isMostRecentEadSubmission={isMostRecentEadSubmission}
              is_amc_lender={is_amc_lender}
              lenderData={lenderData}
              actions={actions}
              send_borrower_appraisal={send_borrower_appraisal}
              auto_send_borrower_appraisal={auto_send_borrower_appraisal}
              auto_send_borrower_appraisal_delay={auto_send_borrower_appraisal_delay}
              review_appraisal_hours_start={review_appraisal_hours_start}
              review_appraisal_hours_end={review_appraisal_hours_end}
              order={order}
              currentUserId={currentUserId}
              refreshOrder={refreshOrder}
              is_review_appraiser={is_review_appraiser}
              onHide={onHide}
              canSeeSubmissionTab={canSeeSubmissionTab}
              viewWarrantyReviews={viewWarrantyReviews}
              compareAppraisalPdfs={compareAppraisalPdfs}
            />
          )
        } else {
          return null
        }
      })}
    </div>
  )
}

export default SubmissionsTab
