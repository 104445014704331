import { ModalBody, ModalCloser, ModalHeader } from '@app/common/modals/antd/ModalComponents'

import { PDFCompareViewer } from './PDFCompareViewer'
import React from 'react'

export function PDFCompareModal({
  currentReport,
  previousReport,
  modalApi,
}) {
  if (!previousReport) { return null }
  return (
    <>
      <ModalHeader className="pdf-view-modal-header"></ModalHeader>
      <ModalCloser onClose={modalApi.cancel} />
      <ModalBody className="pdf-view-modal-body">
        <PDFCompareViewer file1={currentReport} file2={previousReport} />
      </ModalBody>
    </>
  )
}
