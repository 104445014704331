import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Select } from 'antd'

import { ModalBody, ModalHeader, ModalFooter, ModalCloser } from '@common/modals/antd/ModalComponents'
import { postSubmissionReviewV2, fetchRulesets } from '@store/reducers/submissionReviews/submissionReviewsSlice'
import { selectRulesets } from '@store/reducers/submissionReviews/submissionReviewsSelectors'
import { selectOrderSubmissionReviewRulesetForUser } from '@store/reducers/order/orderSelectors'
import {
  selectLenderData,
} from '@store/reducers/user/userSelectors'

export default function ConfirmReviewEngineModal(props) {
  const { order_id, submission_version, hasPreviousReviews, is_amc_lender, viewWarrantyReviews, modalApi } = props
  const dispatch = useDispatch()
  const lenderData = useSelector(selectLenderData)
  const orderRuleset = useSelector(selectOrderSubmissionReviewRulesetForUser)

  const rulesets = useSelector((state) => selectRulesets(state))
  const rulesetOptions = rulesets?.map((ruleset) => ({
    value: ruleset.key,
    label: ruleset.name,
  }))
  const [selectedRuleset, setSelectedRuleset] = useState(null)
  useEffect(() => {
    if (lenderData && !rulesets) {
      dispatch(fetchRulesets({
        lenderId: lenderData.id,
      }
      ))
    }
  }, [dispatch, rulesets, lenderData])

  useEffect(() => {
    if (orderRuleset) {
      setSelectedRuleset(orderRuleset)
    }
  }, [orderRuleset])

  let confirmationText = 'Confirm re-evaluate Reggora Review'
  let textBody = 'Submitting for automated review will overwrite the review progress for the submission. This includes Comments, Actions and Overridden statuses.'
  if (!hasPreviousReviews) {
    confirmationText = 'Confirm Reggora Review Evaluation'
    textBody = ''
  }

  const onConfirm = () => {
    const submission_review_type = viewWarrantyReviews ? 'repurchase_warranty' : (is_amc_lender ? 'amp' : 'lender')
    dispatch(postSubmissionReviewV2({
      orderId: order_id,
      submissionVersion: submission_version,
      submissionReviewType: submission_review_type,
      ruleSetKey: selectedRuleset,
    }))
    modalApi.confirm()
  }

  const handleChange = (value) => {
    setSelectedRuleset(value)
  }

  return (
    <>
      <ModalHeader title={confirmationText}></ModalHeader>
      <ModalCloser onClose={modalApi.cancel} />
      <ModalBody className="pdf-view-modal-body">
        <p>{textBody}</p>

        <p>Choose a ruleset to re-evaluate the submission:</p>
        <Select
          style={{ width: '100%' }}
          onChange={handleChange}
          options={rulesetOptions}
          value={selectedRuleset}>

        </Select>

      </ModalBody>

      <ModalFooter>
        <Button onClick={modalApi.cancel}>Cancel</Button>
        <Button onClick={onConfirm} type="primary">Confirm</Button>
      </ModalFooter>
    </>
  )
}
